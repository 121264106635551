import React, { useState } from "react";

import { isMobile } from "react-device-detect";

import DoneIcon from "@mui/icons-material/Done";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GavelIcon from "@mui/icons-material/Gavel";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

import PracticesStatesHistoryModal from "./PracticesStatesHistoryModal";

function createData(
  id,
  title,
  practice_type_id,
  status,
  business_name,
  liquidated_import
  //colonne tabella
) {
  return {
    id,
    title,
    practice_type_id,
    status,
    business_name,
    liquidated_import,
    //colonne tabella
  };
}

const PracticesList = (practices, userType, frontendParams) => {
  const [clickedIndex, setClickedIndex] = useState(false);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPracticeCode, setSelectedPracticeCode] = useState(null);

  const handleTooltipOpen = (practiceCode) => {
    setClickedIndex(practiceCode);
    setSelectedPracticeCode(practiceCode); // Save the selected practice code
    setModalOpen(true); // Open the modal
    setTimeout(() => setOpen(false), 3000);
  };

  const renderTableHeader = (header, title) => {
    if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "true"
    )
      return (
        <React.Fragment>
          <ArrowDropDownIcon />
          <b>{title}</b>
        </React.Fragment>
      );
    else if (
      frontendParams.order_key === header &&
      frontendParams.order_desc === "false"
    )
      return (
        <React.Fragment>
          <ArrowDropUpIcon />
          <b>{title}</b>
        </React.Fragment>
      );
  };

  const rows = [];

  for (let i = 0; i < practices?.length; i++) {
    rows.push(
      createData(
        practices[i]["practices.code"],
        practices[i]["practices.title"],
        practices[i]["practice_types.value"],
        practices[i]["practice_last_status"],
        userType === "Controller" || userType === "Intermediary"
          ? practices[i]["repairer.business_name"]
          : "",
        practices[i]["practice_liquidated_imports.liquidated_import"]
      )
    );
  }

  const columns = [
    {
      field: "title",
      headerName: "Elenco pratiche",
      flex: 1,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Button
            fullWidth
            size="large"
            sx={{
              textAlign: "left",
              justifyContent: "flex-start",
              display: "block",
              height: userType === "Repairer" && "100%",
            }}
            onClick={() => {
              let baseUrl = "";

              if (userType === "Controller")
                baseUrl = "area_controller/pratiche";
              else if (userType === "Intermediary")
                baseUrl = "area_perito/le_mie_pratiche";
              else if (userType === "Repairer") baseUrl = "le_mie_pratiche";
              else return;

              window.open(`/${baseUrl}/${params.row.id}`, "_blank");
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              fontStyle={"italic"}
              sx={{ mt: isMobile && -0.5, mb: isMobile ? -3 : 0.5 }}
            >
              {params.value}
            </Typography>
            {(userType === "Controller" || userType === "Intermediary") && (
              <>
                {isMobile && <br />}
                <Typography
                  variant="subtitle2"
                  sx={{
                    borderLeft: !isMobile && "1px solid #ddd",
                    lineHeight: 1,
                  }}
                >
                  {params.row.business_name}
                </Typography>
              </>
            )}
          </Button>
        );
      },
    },
    {
      field: "status",
      headerName: "Stato",
      hide: true,
      flex: 1,
      maxWidth: isMobile ? 75 : 250,
      minWidth: isMobile ? 75 : 250,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return (
          <Tooltip
            title={params.value}
            followCursor
            arrow
            open={clickedIndex === params.row.id && open ? true : false}
          >
            <Button
              fullWidth
              onClick={() => handleTooltipOpen(params.row.id)}
              sx={{
                mx: -1,
                height: "100%",
                color: "white",
                borderRadius: 0,
                background:
                  params.value === "Nuova"
                    ? "#42c4f5"
                    : params.value === "Presa in carico"
                    ? "#42a5f5"
                    : params.value === "Richiesta danni inviata"
                    ? "purple"
                    : params.value === "Attesa esito perizia"
                    ? "goldenrod"
                    : params.value === "Perizia conclusa"
                    ? "#85d016"
                    : params.value === "Trattativa con liquidatore"
                    ? "darkorchid"
                    : params.value === "Liquidata"
                    ? "green"
                    : params.value === "Rigettata"
                    ? "red"
                    : params.value === "Contenzioso" && "orangered",
              }}
            >
              <Box sx={{ display: params.value === "Liquidata" && "block" }}>
                <Box sx={{ mb: -1.5 }}>
                  {params.value === "Nuova" ? (
                    <NewReleasesIcon />
                  ) : params.value === "Presa in carico" ? (
                    <HowToRegIcon />
                  ) : params.value === "Richiesta danni inviata" ? (
                    <>
                      <DriveFileMoveIcon />
                      <AccountBalanceIcon />
                    </>
                  ) : params.value === "Attesa esito perizia" ? (
                    <>
                      <PersonSearchIcon />
                      <HourglassTopIcon />
                    </>
                  ) : params.value === "Perizia conclusa" ? (
                    <>
                      <PersonSearchIcon />
                      <MinorCrashIcon />
                    </>
                  ) : params.value === "Trattativa con liquidatore" ? (
                    <>
                      <CurrencyExchangeIcon />
                      <AccountBalanceIcon />
                    </>
                  ) : params.value === "Liquidata" ? (
                    <DoneIcon />
                  ) : params.value === "Rigettata" ? (
                    <CloseIcon />
                  ) : (
                    params.value === "Contenzioso" && <GavelIcon />
                  )}
                </Box>
                {!isMobile && params.value}
                {params.row.liquidated_import && (
                  <Box sx={{ mt: -1 }}>€{params.row.liquidated_import}</Box>
                )}
              </Box>
            </Button>
            {isModalOpen && clickedIndex === params.row.id && (
              <PracticesStatesHistoryModal
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
                practiceCode={selectedPracticeCode}
                title={params.row.title}
              />
            )}
          </Tooltip>
        );
      },
    },
    {
      field: "liquidated_import",
      headerName: "Importo liquidato (Euro)",
      flex: 1,
      maxWidth: 0,
      sortable: false,
      renderHeader: (param) => {
        return renderTableHeader(param.field, param.colDef.headerName);
      },
      renderCell: (params) => {
        return params.row.liquidated_import;
      },
    },
  ];
  return { rows: rows, columns: columns };
};

export default PracticesList;
