import React, { useEffect, useState } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import useGetListOf from "../../hooks/useGetListOf";

const PracticeStatusListFilter = ({ onTypeSet, searchParams, user }) => {
  const statusList = useGetListOf("Practice_States", "complete=true&order_key=id", "list");
  const [selectedType, setSelectedType] = useState("");
  const [open, setOpen] = useState(false);

  const handleChange = (value) => {
    setSelectedType(value);
    onTypeSet(value, "status");
  };

  useEffect(() => {
    if (searchParams.size === 0) setSelectedType("");
  }, [searchParams]);

  return (
    <>
      {statusList && statusList.length !== 0 && (
        <FormControl size="small" sx={{ width: "100%" }}>
          <InputLabel>Stato pratica</InputLabel>
          <Select
            value={selectedType}
            label="Stato pratica"
            onChange={(e) => handleChange(e.target.value)}
            open={open}
            onClick={(e) => setOpen(!open)}
          >
            <MenuItem value="">-</MenuItem>
            {statusList.listElements.map((type) => (
              <MenuItem value={type["practice_states.value"]}>
                {type["practice_states.value"]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default PracticeStatusListFilter;
