import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import useGetListOf from "../../hooks/useGetListOf";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { isMobile } from "react-device-detect";
import LoadingSpinner from "../layout/LoadingSpinner";
import { Box } from "@mui/system";
import PracticeLiquidatedImportInputModal from "./PracticeLiquidatedImportInputModal";

const PracticesDetailStatusChangeSelect = ({ practiceCode, currentState }) => {
  const axiosPrivate = useAxiosPrivate();
  const states = useGetListOf("Practice_States", "", "list").listElements;
  const [selectedState, setSelectedState] = useState("");
  const [choiceName, setChoiceName] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const submitChange = async (event) => {
    const newValue = event.target.value;
    // setSelectedState(newValue); // Aggiorna il valore selezionato
    setLoading(true);
    if (window.confirm("Lo stato della pratica verrà aggiornato. Procedere?")) {
      try {
        const response = await axiosPrivate.post(
          "/Practice_Progresses/create",
          {
            practice_code: practiceCode,
            practice_state_id: newValue,
          }
        );
        if (response) {
          window.location.reload(); // Usa reload invece di reimpostare href
        }
      } catch (err) {
        setLoading(false);
        console.error("Errore durante la richiesta:", err);
        if (!err?.response) {
          window.alert("Errore. Riprova");
        } else if (err.response?.status === 401) {
          window.alert("Non sei autorizzato");
        } else {
          window.alert("Errore. Riprova");
        }
      }
    } else {
      setSelectedState("");
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Box sx={{ mt: -2, mb: -3 }}>
          <LoadingSpinner />
        </Box>
      ) : (
        <FormControl size="small" fullWidth sx={{ mt: isMobile && 3 }}>
          <InputLabel id="change-status">Aggiorna stato pratica</InputLabel>
          <Select
            labelId="change-status"
            id="demo-simple-select"
            value={selectedState} // Usa il valore dallo stato
            label="Aggiorna stato pratica"
            onChange={(e) => {
              const selectedValue = e.target.value;
              const selectedStateName = states.find(
                (state) => state.id === selectedValue
              )?.value;
              setSelectedState(selectedValue);
              // Mostra l'alert subito
              if (selectedStateName === "Liquidata") setOpen(true);
              else submitChange(e);
            }}
          >
            {states?.map((state) =>
              state.value !== "Nuova" &&
              state.value !== "Presa in carico" &&
              state.value !== currentState ? (
                <MenuItem key={state.id} value={state.id}>
                  {state.value}
                </MenuItem>
              ) : null
            )}
          </Select>
          {open && (
            <PracticeLiquidatedImportInputModal
              open={open}
              onClose={() => {
                setSelectedState("");
                setOpen(false);
              }}
              practiceCode={practiceCode}
              selectedState={selectedState}
            />
          )}
        </FormControl>
      )}
    </>
  );
};

export default PracticesDetailStatusChangeSelect;
