import React, { useEffect, useRef, useState } from "react";

import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import useGetListOf from "../../hooks/useGetListOf";
import ElementsList from "../ElementsList";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingSpinner from "../layout/LoadingSpinner";
import { isMobile } from "react-device-detect";

const PracticeLiquidatedImportInputModal = ({
  open,
  onClose,
  practiceCode,
  selectedState,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const submitChange = async (event) => {
    const newValue = event.target.value;
    // setSelectedState(newValue); // Aggiorna il valore selezionato
    setLoading(true);
    if (
      window.confirm(
        `Lo stato della pratica verrà aggiornato in \"Liquidata\" con l'importo di ${value}€. Procedere?`
      )
    ) {
      try {
        const response = await axiosPrivate.post(
          "/Practice_Liquidated_Imports/create",
          {
            practice_code: practiceCode,
            liquidated_import: value,
          }
        );
        if (response) {
          const response = await axiosPrivate.post(
            "/Practice_Progresses/create",
            {
              practice_code: practiceCode,
              practice_state_id: selectedState,
            }
          );
          if (response) {
            window.location.reload(); // Usa reload invece di reimpostare href
          }
        }
      } catch (err) {
        setLoading(false);
        console.error("Errore durante la richiesta:", err);
        if (!err?.response) {
          window.alert("Errore. Riprova");
        } else if (err.response?.status === 401) {
          window.alert("Non sei autorizzato");
        } else {
          window.alert("Errore. Riprova");
        }
      }
    } else {
      // setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative", overflow: "hidden" }}>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Indica l'importo liquidato
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          // height: 500,
          overflow: "auto",
          background: "#f9f9f9",
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        Per marcare la pratica come "LIquidata" devi indicare l'importo erogato
        dalla compagnia assicurativa per il risarcimento danni
        <Box sx={{ px: isMobile ? "20%" : "30%" }}>
          <TextField
            fullWidth
            name="import"
            label="Importo euro"
            sx={{ my: 2 }}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              },
              htmlInput: {
                min: "1",
              },
            }}
            type="number"
            onChange={(e) => {
              console.log(e.target.value);
              setValue(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") submitChange();
            }}
          />
        </Box>
        {loading ? (
          <Box sx={{ textAlign: "center" }}>
            <LoadingSpinner />
          </Box>
        ) : (
          <>
            <Button
              fullWidth
              variant="contained"
              onClick={submitChange}
              disabled={value === "" || value <= 0}
              sx={{ mb: 1 }}
            >
              Invio
            </Button>
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="error"
              onClick={handleClose}
              sx={{ mb: 1 }}
            >
              Annulla
            </Button>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default PracticeLiquidatedImportInputModal;
